const observees = document.querySelectorAll("[data-comp-observee]");

observees.forEach((observee) => {
    const attributes = JSON.parse(observee.getAttribute("data-comp-observee"));

    const observer = new IntersectionObserver(
        (entries) => handleIntersections(entries, attributes),
        {
            threshold: attributes?.threshold ?? 0.25,
            rootMargin: attributes?.rootMargin ?? '0%'
        }
    );

    observer.observe(observee);
});

const observeeCallbacks = {
    scrollPhone: (entry, args) => {
        const phone = document.getElementById("patient-phone");
        const image = phone.children[args[0]];
        phone.scrollTo(0, image.offsetTop);

        const container = document.querySelector('[data-phone-container]');
        container.classList.forEach((className) => {
            if (className.startsWith('slide-')) {
                container.classList.remove(className);
            }
        })
        container.classList.add(args[1]);
    },

    triggerMouseClickAnimation: (entry, args) => {
        const pointer = document.querySelector(".b2b-page-hero__image-pointer");
        const video = entry.target;

        const videoAlreadyPlaying = !!(video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2);

        const videoPlayAlreadyTriggered = video.classList.contains("already-played");

        if (videoAlreadyPlaying) {
            pointer.classList.add("playing");
        } else if (!videoPlayAlreadyTriggered) {
            pointer.classList.add("playing");
            video.play();
            video.classList.add("already-played");
        }
    }
};

const schedulingHeroVideo = document.getElementById('scheduling-hero-video');
const schedulingHeroPointer = document.getElementById('scheduling-hero-pointer');
if (schedulingHeroVideo) {
    schedulingHeroVideo.addEventListener('click', () => {

        const videoAlreadyPlaying = !!(schedulingHeroVideo.currentTime > 0 && !schedulingHeroVideo.paused && !schedulingHeroVideo.ended && schedulingHeroVideo.readyState > 2);

        if (videoAlreadyPlaying) {
            return
        }

        schedulingHeroPointer.classList.remove("playing");
        setTimeout(() => {
            schedulingHeroPointer.classList.add("playing");
            schedulingHeroVideo.play();
        }, 10);


    });
}

const handleIntersections = (entries, attributes) => {
    const callback = observeeCallbacks[attributes?.callback];

    entries.forEach((entry) => {
        entry.target.classList.toggle("observing", entry.isIntersecting);

        if (entry.isIntersecting) {
            entry.target.classList.add("observed");
            callback && callback(entry, attributes?.args);
        }
    });
};

import { DotLottie } from "https://cdn.jsdelivr.net/npm/@lottiefiles/dotlottie-web/+esm";

const players = document.querySelectorAll('.lottie-player');

players.forEach(player => {

    player.addEventListener('click', () => {
        const sources = [player.dataset.videoOne, player.dataset.videoTwo, player.dataset.videoThree]

        let count = 1;
        let dotLottie = new DotLottie({
            autoplay: true,
            canvas: player,
            src: sources[0]
        });

        function completeListener(lottie) {
            lottie.addEventListener('complete', () => {
                count++;
                if (count == 3) {
                    return
                }
                dotLottie.destroy
                dotLottie = new DotLottie({
                    autoplay: true,
                    canvas: player,
                    src: sources[count]
                });
                completeListener(dotLottie);
            })
        }

        completeListener(dotLottie);
    })
});
